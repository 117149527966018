<template>
  <div class="app-container">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="公司名称" prop="comName">
        <el-input
          v-model="form.comName"
          placeholder="请输入公司名称，用于标识您已购买，个人授权无需填写"
        />
      </el-form-item>
      <el-form-item label="企业代码" prop="comCode">
        <el-input
          v-model="form.comCode"
          placeholder="请输入企业信用代码，用于标识您已购买，个人授权无需填写"
        />
      </el-form-item>
      <el-form-item label="联系人" prop="linkMan">
        <el-input
          v-model="form.linkMan"
          placeholder="请输入联系人，用于标识您已购买"
        />
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="form.phone"
          placeholder="请输入联系电话，方便接收更新通知"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import { addImpower } from '@/api/plugin'

export default {
  name: 'impower',
  props: {
    pluginId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      form: {},
      rules: {
        linkMan: [
          {
            required: true,
            message: '联系人不能为空',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: '联系电话不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 取消按钮
    cancel () {
      this.reset()
      this.$emit('exit')
    },
    // 表单重置
    reset () {
      this.form = {
        pluginId: undefined,
        comName: undefined,
        comCode: undefined,
        linkMan: undefined,
        phone: undefined
      }
    },
    submitForm () {
      this.form.pluginId = this.pluginId
      this.$refs.form.validate((valid) => {
        if (valid) {
          addImpower(this.form).then(res => {
            if (res.code === 0) {
              this.reset()
              this.$emit('success')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .dialog-footer{
    display: flex;
    flex-direction:row;
    flex-wrap: nowrap;
    justify-content:flex-end;
  }
</style>
